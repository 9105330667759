/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import VTabs from "components/v-core/v-tabs";
import Buy from "./components/Buy";
import Sell from "./components/Sell";
import Send from "./components/Send";
import Exchange from "./components/Exchange";
const tabs = [
  {
    name: "Exchange",
    value: "exchange",
  },
  {
    name: "Buy",
    value: "buy",
  },
  {
    name: "Sell",
    value: "sell",
  },
  {
    name: "Send",
    value: "send",
  },
];

const StockActions = () => {
  // const history = useHistory();
  // const searchParams = new URLSearchParams(window.location.search);
  // const currentTab = searchParams.get("tab");

  // useEffect(() => {
  //   if (!currentTab) {
  //     history.push("?tab=buy");
  //   }
  // }, [currentTab]);

  // const handleTabs = (tab: string) => {
  //   history.push(`?tab=${tab}`);
  // };

  const [currentTab, setCurrentTab] = useState(null);

  useEffect(() => {
    if (!currentTab) {
      setCurrentTab(tabs[0].value);
    }
  }, [currentTab]);

  const handleTabs = (tab: string) => {
    setCurrentTab(tab);
  };

  return (
    <div>
      <div className="mb-3 max-md:mb-0">
        <VTabs
          tabs={tabs}
          type="button"
          tabActive={currentTab}
          onChangeTabs={handleTabs}
        />
      </div>
      <div className="mt-3 max-md:mt-0">
        {currentTab === "exchange" && <Exchange />}
        {currentTab === "buy" && <Buy />}
        {currentTab === "sell" && <Sell />}
        {currentTab === "send" && <Send />}
      </div>
    </div>
  );
};

export default StockActions;
