/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { useEffect, useState, useContext, useMemo, Fragment } from "react";
import { MasterContext } from "context/MasterContext";
import { ThemeContext } from "context/ThemeContext";

import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { ROUTES } from "config/routes";

import Donate from "layouts/Donate";

import UserHeader from "layouts/UserHeader";
import Icons from "helper/icons";
import { Dialog, Transition } from "@headlessui/react";
import VButton from "components/v-core/v-button";
import RightSidebar from "layouts/MainLayout/right-sidebar";
import Menus from "layouts/Menus";

export default function View() {
  const history = useHistory();
  const { settings, setAuth } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);
  const logos = settings.logos;

  const [openMenu, setOpenMenu] = useState<boolean>(true);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const logoutUser = () => {
    setAuth(null);
    localStorage.clear();
    history.push(ROUTES.LOGIN);
  };

  useEffect(() => {
    if (isMobile) {
      setOpenMenu(false);
    }
  }, []);

  return (
    <>
      {/* {isMobile && ( */}
      <div className="md:hidden ">
        <div className="m-auto items-center flex justify-between w-full fixed top-[60px]">
          <div
            className="flex justify-center items-center px-[10px] transition duration-300 ease-in-out"
            onClick={() => setOpenMenu(!openMenu)}
          >
            {!openMenu ? (
              <Icons iconClassName="w-8 h-8" iconName="menu_mobile" />
            ) : (
              <Icons iconClassName="w-10 h-10" iconName="close" />
            )}
          </div>
          <div
            className={`cursor-pointer m-auto flex items-center mb-0 justify-center z-50 ${
              isMobile
                ? "w-[150px]"
                : openMenu
                ? "w-[240px] p-6"
                : "w-[70px] p-0"
            }
            ${isMobile ? "h-14" : "h-[75px]"}`}
          >
            <img
              src={`${settings?.logos?.logo || "/assets/images/logo.png"}`}
              alt="Vzonex.com"
            />
          </div>
          {isMobile && (
            <div className={`flex`}>
              <UserHeader />
              <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-50 lg:hidden"
                    onClose={setSidebarOpen}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="transition-opacity ease-linear duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity ease-linear duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex justify-end">
                      <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                      >
                        <Dialog.Panel className="relative ml-16 flex w-full max-w-xs flex-1">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="absolute right-full top-3 left-0 flex w-16 justify-center z-50">
                              <VButton
                                type="outline"
                                className="!border-none"
                                iconLeft={<Icons iconName="close" />}
                                onClick={() => setSidebarOpen(false)}
                              />
                            </div>
                          </Transition.Child>
                          {/* Sidebar component, swap this element with another sidebar if you like */}
                          <div
                            className="flex grow flex-col overflow-y-hidden"
                            style={themesSetting?.menus}
                          >
                            <RightSidebar />
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </Dialog>
                </Transition.Root>
                <Transition.Root show={openMenu} as={Fragment}>
                  <Dialog
                    as="div"
                    className="relative z-50 lg:hidden"
                    onClose={setOpenMenu}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="transition-opacity ease-linear duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity ease-linear duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                      <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                      >
                        <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="absolute right-0 top-1 flex w-16 justify-center z-50">
                              <VButton
                                type="outline"
                                className="!border-none"
                                iconLeft={<Icons iconName="close" />}
                                onClick={() => setOpenMenu(false)}
                              />
                            </div>
                          </Transition.Child>
                          {/* Sidebar component, swap this element with another sidebar if you like */}
                          <div
                            className={`relative min-w-[320px] flex grow flex-col gap-y-5 overflow-y-hidden px-6 pb-2 z-40 ${
                              isMobile && "pt-3"
                            }`}
                            style={themesSetting.menus}
                          >
                            <>
                              <Donate setOpenMenu={setOpenMenu} />

                              <Menus
                                openMenu={openMenu}
                                setOpenMenu={setOpenMenu}
                              />
                              <div
                                className="mt-0 w-full flex justify-center cursor-pointer"
                                onClick={logoutUser}
                              >
                                <Icons iconName="signOut" />
                                {openMenu && (
                                  <span className="ml-[5px]">Sign out</span>
                                )}
                              </div>
                              {/* <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full justify-center items-center flex flex-col py-2"></div> */}
                            </>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </Dialog>
                </Transition.Root>
                <VButton
                  type="outline"
                  className="!border-none"
                  iconLeft={<Icons iconName="wallets" />}
                  onClick={() => setSidebarOpen(true)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {/* )} */}
      <div
        className={`${
          openMenu ? "w-[100vw]" : isMobile ? "w-0" : "w-[90px] "
        } ${
          isMobile && "hidden"
        } md:max-w-[316px] h-full z-10 relative transition-all duration-1000 ease-in-out px-[6px] py-[24px] ${
          isMobile && !openMenu && "!p-0"
        }`}
      >
        {!isMobile && (
          <div
            onClick={() => setOpenMenu(!openMenu)}
            className={`${
              openMenu ? "rotate-0" : "rotate-180"
            } absolute z-40 -right-[20px] top-10 cursor-pointer transition-all duration-1000 ease-in-out `}
          >
            <img
              src={`/assets/images/openMenu.png`}
              alt="open"
              className="w-[40px] max-w-none"
            />
          </div>
        )}

        <div
          className={`${
            openMenu
              ? "w-full h-screen fixed z-50"
              : isMobile
              ? "w-0 "
              : "w-full"
          } flex justify-center overflow-hidden relative top-[90px] md:top-0 transition-all duration-1000 ease-in-out `}
        >
          {" "}
          {!isMobile && (
            <div
              className={`${openMenu ? "p-6" : "p-0"} ${
                isMobile ? "w-[150px]" : openMenu ? "w-60" : "w-[50px]"
              } ${
                isMobile ? "h-14" : "h-[50px]"
              } cursor-pointer m-auto flex items-center mb-0 absolute z-20 transition-all duration-1000 ease-in-out `}
            >
              <img
                src={
                  openMenu
                    ? settings?.logos?.logo
                    : settings?.logos?.logoResponsive
                }
                alt="Vzonex.com"
                className="w-full"
              />
            </div>
          )}
          <>
            <div
              className={`relative md:h-[100vh] h-[calc(90vh-60px)] w-full overflow-hidden mt-2 ${
                isMobile ? "top-5" : "top-0"
              }`}
            >
              <div
                className={`flex flex-col overflow-y-auto no-scrollbar ${
                  isMobile ? "mt-0" : "mt-[60px]"
                } h-[calc(90%-60px)] md:h-[calc(90%-80px)]
                w-full gap-y-3`}
              >
                <div className="w-full justify-center items-center flex flex-col py-2">
                  <Donate setOpenMenu={setOpenMenu} />
                  {/* <div
                  className="mt-0 flex items-center py-2 cursor-pointer"
                  onClick={logoutUser}
                >
                  <Icons iconName="signOut" />
                  {openMenu && <span className="ml-[5px]">Sign out</span>}
                </div> */}
                </div>
                <Menus openMenu={openMenu} setOpenMenu={setOpenMenu} />
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}
