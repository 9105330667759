import React from "react";
import Draggable from "react-draggable";
import VInput from "components/v-core/v-input";
import { convertS3Link } from "api/s3-request";

type IProps = {
  data?: any;
  type?: string;
  isEdit?: boolean;
  setData?: (e: any) => void;
};

const CardItem = (props: IProps) => {
  const { data, type } = props;

  return (
    <div className="w-full flex justify-center">
      <div
        ref={data[type]?.containerRef}
        className="w-[243px] h-[153px] mx-auto relative"
      >
        <img
          src={convertS3Link(data[type]?.background)}
          alt={`bank name`}
          className="w-full h-full rounded-lg object-cover absolute top-0 left-0"
        />
        {type === "front" && (
          <Draggable
            bounds="parent"
            position={data?.logo?.position}
            disabled={true}
          >
            <div className="w-[6rem] h-[3rem]">
              <img
                className="max-w-full max-h-full rounded object-cover"
                src={data?.logo?.src}
                alt="logo"
              />
            </div>
          </Draggable>
        )}
        {data[type]?.userInputs?.map((input) => (
          <Draggable
            key={input.id}
            // bounds="parent"
            disabled={true}
            position={input?.position}
          >
            <div>
              <VInput
                disabled={true}
                inputStyle={{
                  fontSize: `${input.font_size}px`,
                  fontWeight: `${input.font_weight}`,
                  color: `${input.color}`,
                }}
                type="text"
                value={input.value}
                className={`!w-auto !border-none`}
              />
            </div>
          </Draggable>
        ))}
      </div>
    </div>
  );
};

export default CardItem;
