import { ThemeContext } from "context/ThemeContext";
import React, { useContext } from "react";
import Balances from "./Balances";
import Histories from "./Histories";
import StockActions from "./StockActions";

const RightSidebar = () => {
  const { themesSetting } = useContext(ThemeContext);

  return (
    <div className="h-full">
      <div className="flex flex-col gap-y-6 max-md:gap-y-2">
        <div className="px-3">
          <Balances />
        </div>
        <div
          className="border-b border-dashed w-full"
          style={{ borderColor: themesSetting?.header?.borderColor }}
        ></div>
        <div className="px-3">
          <StockActions />
        </div>
        <div
          className="border-b border-dashed "
          style={{ borderColor: themesSetting?.header?.borderColor }}
        ></div>
      </div>
      <div className="flex flex-col gap-y-1 md:gap-y-3 px-3 py-1 md:py-3 w-full h-[calc(100%-495px)] md:h-[calc(100%-682px)] ">
        <span className="text-base font-medium">Available stocks</span>
        {/* <div className="w-full flex justify-center">
          <img
            className="max-xl:w-[150px]"
            src="/assets/images/stock-chart.png"
            alt="stock chart"
          />
        </div> */}
        <Histories />
      </div>
    </div>
  );
};

export default RightSidebar;
